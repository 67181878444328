import Vue from 'vue';
import VueRouter from 'vue-router';
import App from './App.vue';

import './assets/style.scss';
import './assets/vendor/bootstrap-grid.css';

Vue.use(VueRouter);

import About from './components/About.vue';
import Careers from './components/Careers.vue';
import Contact from './components/Contact.vue';
import Home from './components/Home.vue';
import Services from './components/Services.vue';
import Work from './components/Work.vue';

const routes = [
    { path: '/', component: Home },
    { path: '/about', component: About },
    { path: '/careers', component: Careers },
    { path: '/contact', component: Contact },
    { path: '/services', component: Services },
    { path: '/work', component: Work },
];

const router = new VueRouter({
    routes,
    mode: 'history',
});

new Vue({
    el: '#app',
    router,
    render: h => h(App),
});
