<template>
    <div>
        <Banner :title="'Our <br> Services'" />
        <Tabs :tabs="tabs" />
    </div>
</template>
<script>
import Banner from './Banner';
import Tabs from './Tabs';
export default {
    name: 'services',
    components: {
        Banner,
        Tabs,
    },
    data() {
        return {
            image: '../assets/images/temp/intro-image-2.png',
            tabs: [
                {
                    title: 'Web App Development',
                    heading: 'Software and so much more.',
                    html: `
                        <p>The in’s and out’s of software development can get complicated, but don’t let it get you down. Make the call and lean on our team of experts to help you through it.</p>
                        <p>We’re here to listen, advise, and navigate you through the complexity so your business can meet its goals.</p>
                        <p>When software is the answer to your problem, partner with us and join a team that sets you up for success.</p>
                    `,
                },
                {
                    title: 'Scalable & Resilient',
                    heading: 'Apps of steel.',
                    html: `
                        <p>Our applications are built to take on the ebb and flow of web traffic. They handle peaks with ease while delivering the same smooth experience to each individual visitor.</p>
                        <p>For example, envision a payment process that’s consistent and resilient to problems. That’s what we can deliver. Our solutions can withstand issues and function without a snag for your customers.</p>
                        <p>Our team collaborates with you throughout this process to deliver a tight application that lasts - saving you from malfunctions, downtime, and failures.</p>
                        <p>Just imagine the amount of time, energy, and money you’ll save by building your next web app right—with us.</p>
                    `,
                },
                {
                    title: 'Enterprise SEO',
                    heading: 'Boost your search ranking organically with SEO.',
                    html: `
                        <p>Search Engine Optimization (SEO) uses technical techniques and methodologies to help boost your company’s ranking in internet search results -- without relying on paid (cost-per-click) ads.</p>
                        <p>Companies, small and large alike, can manage their own SEO if they dedicate the time, diligence, and ongoing commitment to write quality content and stay current with various schema advances, and understand how sites are crawled, indexed and ultimately ranked.</p>
                        <p>We take the burden away and deliver clean data (that the “crawlers” love) with consistent location and content management along with ranking results.</p>
                        <p>Our team is experienced, knowledgeable, and keen on keeping up with the changing landscape to help your brand rank higher — organically.</p>

                    `,
                },
                {
                    title: 'Enterprise SEM',
                    heading: 'Top the list with SEM.',
                    html: `
                        <p>Pay-per-click (PPC) marketing on search engines is a way to ensure top billing on searches — on your terms and budget. Our trained staff knows the ropes of paid placements and can devise a plan to get more eyes and visits to your site with Search Engine Marketing (SEM).</p>
                        <p>Together, we can create a system and put your marketing dollars to work in smart and effective ways.</p>
                        <p>Rely on us for SEM, to see your Company’s name on top, and enjoy effective returns on your advertising investment.</p>
                    `,
                },
                {
                    title: 'Big Data, Big Results',
                    heading: 'Make big data deliver bigger results.',
                    html: `
                        <p>The amount of customer information companies garner is mind-boggling. It can also be debilitating without knowing how to siphon and uncover insights to inform your strategies.</p>
                        <p>We can help you mine, discover, and apply key learnings to help you grow your business, connect with customers, and meet your goals.</p>
                        <p>With the right collaboration and partnership, you can use big data to harvest smarter, more actionable information, and bigger results. </p>
                    `,
                },
            ],
        };
    },
};
</script>
<style lang="scss" scoped>
.intro {
    background-image: url('../assets/images/temp/intro-image-2.png');
}
</style>
