<template>
    <div>
        <Banner :title="'Careers'" />
        <section class="section section--services">
            <div class="shell">
                <div class="tabs">
                    <div class="tabs__head"></div>
                    <div class="tabs__body">
                        <h3>Driven to Build</h3>
                        <p>We love what we do. There’s something in the challenge of finding and building custom solutions for our partners that excites us. And, we develop great software because of it.</p>
                        <p>We’re adding smart, enthusiastic, and collaborative people to our team. If that’s you, let’s talk.</p>

                        <h3>The Perks</h3>
                        <span>Impact</span>
                        <p>We’re a relatively small team. Each person contributes and sees the impact of his or her work. Once you join, you can expect to step in and help out right away.</p>

                        <span>Benefits</span>
                        <p>Our employees receive a great benefits package: 401k, profit sharing, medical and dental insurance, and onsite massages … yep, you read it right: massages!</p>

                        <span>Team</span>
                        <p>Our people are smart, easy-going, loyal, and ambitious. As a team, we encourage, help, and respect each other in everything we do.</p>

                        <span>Food</span>
                        <p>Our entire team takes break from work to eat together once a week -- with free drinks and snacks. It’s a fun tradition that keeps us close.</p>

                        <span>Education</span>
                        <p>Professional growth is one of those things that keeps work fresh. Whatever you need to learn on the job, you can trust we’ll help you be your best.</p>

                        <span>Workspace</span>
                        <p>We’ve created a modern and comfortable open workspace within an older industrial building. The mix gives our space a unique and creative personality.</p>

                        <h3>Build solutions with purpose. Join Mirus.</h3>

                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import Banner from './Banner';
export default {
    name: 'careers',
    components: {
        Banner,
    },
    data() {
        return {};
    },
};
</script>
<style lang="scss" scoped>
.intro {
    background-image: url('../assets/images/temp/intro-image-2.png');
}
span {
    font-style: italic;
    font-size: 24px;
}
</style>
