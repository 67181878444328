<template>
    <div>
        <div class="intro">
            <div class="shell">
                <div class="intro__inner">
                    <div class="row">
                        <div class="col-lg-8">
                            <div class="intro__content">
                                <h1>Applications that build your business.</h1>

                                <p>
                                    Packaged software won't give you a competitive advantage, but building services
                                    and interfaces to tie the core applications together will.
                                </p>

                                <a href="/work/" class="btn btn--default">Let us show you how</a>
                            </div>
                            <!-- /.intro__content -->
                        </div>
                        <!-- /.col-lg-8 -->
                    </div>
                    <!-- /.row -->
                </div>
                <!-- /.intro__inner -->
            </div>
            <!-- /.shell -->
        </div>
        <!-- /.intro -->
        <section class="section">
            <div class="section__backgrounds">
                <div class="section__background section__background--light"></div>
                <!-- /.section-background -->

                <div class="section__background"></div>
                <!-- /.section-background -->
            </div>
            <!-- /.section-backgrounds -->

            <div class="shell">
                <div class="features">
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <div class="feature">
                                <div class="feature__icon">
                                    <i class="ico-search"></i>
                                </div>
                                <!-- /.feature__icon -->

                                <h4>Scalable and Resilient Web Applications</h4>

                                <p>
                                    Mirus builds solutions that are both scalable, adjusting capacity to meet
                                    demand and resilient, built to withstand the unexpected.
                                </p>

                                <a href="/services" class="link-more">Search no more</a>
                            </div>
                            <!-- /.feature -->
                        </div>
                        <!-- /.col-lg-6 -->

                        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <div class="feature feature--alt">
                                <div class="feature__icon">
                                    <svg
                                        class="ico-partner"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewbox="0 0 215.12 149.38"
                                        width="188"
                                        height="130"
                                    >
                                        <g id="Layer_2" data-name="Layer 2">
                                            <g id="Layer_1-2" data-name="Layer 1">
                                                <path
                                                    class="cls-1"
                                                    d="M199.09,18.18a14.8,14.8,0,0,0-2-7.45L199.9,9.1a18.23,18.23,0,0,0-6.65-6.66l-1.63,2.83a14.8,14.8,0,0,0-7.45-2V0a18.19,18.19,0,0,0-9.09,2.44l1.63,2.83a15,15,0,0,0-5.46,5.46L168.43,9.1A18.1,18.1,0,0,0,166,18.18h3.26a14.89,14.89,0,0,0,2,7.45l-2.82,1.63a18.2,18.2,0,0,0,6.65,6.65l1.63-2.82a14.87,14.87,0,0,0,7.46,2v3.26a18.13,18.13,0,0,0,9.08-2.44l-1.63-2.83a14.89,14.89,0,0,0,5.46-5.46l2.82,1.63a18,18,0,0,0,2.45-9.08Zm-14.92,9.09a9.09,9.09,0,1,1,9.09-9.09A9.08,9.08,0,0,1,184.17,27.27Z"
                                                ></path>
                                                <path
                                                    class="cls-2"
                                                    d="M166.85,44a23.61,23.61,0,0,0-3.19-11.86l4.49-2.59a29,29,0,0,0-10.58-10.59l-2.6,4.5a23.59,23.59,0,0,0-11.86-3.2V15a28.85,28.85,0,0,0-14.45,3.88l2.59,4.5a23.8,23.8,0,0,0-8.69,8.68l-4.49-2.59A28.78,28.78,0,0,0,114.18,44h5.19a23.65,23.65,0,0,0,3.19,11.87l-4.49,2.59A29,29,0,0,0,128.66,69l2.59-4.5a23.59,23.59,0,0,0,11.86,3.2v5.18A28.81,28.81,0,0,0,157.57,69L155,64.5a23.72,23.72,0,0,0,8.69-8.68l4.49,2.59A28.79,28.79,0,0,0,172,44ZM143.11,58.42A14.47,14.47,0,1,1,157.58,44,14.47,14.47,0,0,1,143.11,58.42Z"
                                                ></path>
                                                <rect
                                                    class="cls-3"
                                                    y="8.11"
                                                    width="131.7"
                                                    height="81.26"
                                                    rx="6"
                                                    ry="6"
                                                ></rect>
                                                <polygon
                                                    class="cls-3"
                                                    points="25.56 84.25 25.56 105.75 49.79 81.52 25.56 84.25"
                                                ></polygon>
                                                <rect
                                                    class="cls-4"
                                                    x="83.41"
                                                    y="51.74"
                                                    width="131.7"
                                                    height="81.26"
                                                    rx="6"
                                                    ry="6"
                                                ></rect>
                                                <polygon
                                                    class="cls-4"
                                                    points="190.12 127.89 190.12 149.38 165.88 125.15 190.12 127.89"
                                                ></polygon>
                                                <path
                                                    class="cls-2"
                                                    d="M65,63.4A4.42,4.42,0,1,1,60.61,59,4.42,4.42,0,0,1,65,63.4Z"
                                                ></path>
                                                <path
                                                    class="cls-5"
                                                    d="M76,41.63A14.94,14.94,0,1,1,61,26.69,14.94,14.94,0,0,1,76,41.63Z"
                                                ></path>
                                                <path
                                                    class="cls-4"
                                                    d="M68.16,58.85H53.93a2.28,2.28,0,1,1,0-4.55H68.16a2.28,2.28,0,0,1,0,4.55Z"
                                                ></path>
                                                <path
                                                    class="cls-4"
                                                    d="M68.16,63.4H53.93a2.28,2.28,0,1,1,0-4.55H68.16a2.28,2.28,0,0,1,0,4.55Z"
                                                ></path>
                                                <path
                                                    class="cls-5"
                                                    d="M167.23,95.59h-.06a11,11,0,1,0-16.91-14,11,11,0,1,0-16.91,14h-.09l.57.45a9.71,9.71,0,0,0,1.28,1l15.14,12.09,15-12a11.19,11.19,0,0,0,1.62-1.29Z"
                                                ></path>
                                            </g>
                                        </g>
                                    </svg>
                                </div>
                                <!-- /.feature__icon -->

                                <h4>Building software people love to use</h4>

                                <p>
                                    We aren’t content with building software that is merely useful. We want to
                                    build the great products that our customers (and their customers) will love and
                                    rave about.
                                </p>

                                <a href="/services" class="link-more link-more--light">Turn customers into fans</a>
                            </div>
                            <!-- /.feature -->
                        </div>
                        <!-- /.col-lg-6 -->
                    </div>
                    <!-- /.row -->
                </div>
                <!-- /.features -->
            </div>
            <!-- /.shell -->
        </section>
        <!-- /.section -->
    </div>
</template>

<script>
export default {
    name: 'home',
    data() {
        return {};
    },
};
</script>
<style>
.intro {
    background-image: url('../assets/images/temp/intro-image.jpg');
}
.cls-1 {
    fill: #df6326 !important;
}
.cls-2 {
    fill: #313131 !important;
}
.cls-3 {
    fill: #fff;
}
.cls-4 {
    fill: #d0d2d3;
}
.cls-5 {
    fill: #f48120;
}

/* ------------------------------------------------------------ *\
	Features
\* ------------------------------------------------------------ */

.feature {
    padding: 77px 40px 90px 0;
}

.feature .feature__icon {
    min-height: 130px;
    margin-bottom: 18px;
}

.feature h4 {
    font-weight: 600;
}

.feature--alt {
    padding-left: 65px;
}

@media (max-width: 991px) {
    .features {
        margin: 0 -22px;
    }

    .feature {
        padding: 20px 20px 56px 20px;
        background: #e2e2e2;
    }
    .feature--alt {
        background: #f58220;
    }
}

@media (max-width: 767px) {
    .feature .feature__icon {
        min-height: auto;
        margin-bottom: 25px;
    }
    .feature .ico-search {
        width: 84px;
        height: 68px;
    }
    .feature .ico-partner {
        width: 127px;
        height: 88px;
    }

    .feature h4 {
        font-size: 20px;
    }
    .feature p {
        margin-bottom: 12px;
        font-size: 17px;
    }
    .feature a {
        font-size: 17px;
    }
}
</style>
