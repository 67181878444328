<template>
    <div>
        <Banner :title="'About'"/>
        <section class="section section--services">
             <div class="shell">
                <div class="tabs">
                    <div class="tabs__head"></div>
                    <div class="tabs__body">
                        <h3>We don't have a product line.</h3>
                        <p>
                            We don’t have a tagline either. Nor do we have an ad agency. We’re straight shooters.
                        </p>

                        <p>
                            We simply have a mission:
                            Mirus Research designs custom digital and software solutions to help customers
                            realize the greatest possible value from their technology investments.
                        </p>

                        <p>
                            We also have a way of doing things, which includes:
                            <ul>
                                <li>Combining technology and creativity to provide a compelling user experience</li>
                                <li>Feature-driven, flexible design methodologies</li>
                                <li>Iterative, client-driven improvement cycles</li>
                                <li>Simple, clean code</li>
                                <li>Extensive testing</li>
                                <li>Measurement &amp; metrics to reduce risk</li>
                            </ul>
                            Most importantly, we have a passion for doing business as a team.
                            We invite you to talk with us about how we can put that passion to work on your next project.
                        </p>
                    </div>
                </div>
             </div>
         </section>
    </div>
 </template>
<script>
import Banner from './Banner';
export default {
    name: 'about',
    components: {
        Banner,
    },
    data() {
        return {};
    },
};
</script>
<style lang="scss" scoped>
.intro {
    background-image: url('../assets/images/temp/intro-image-2.png');
}
ul{
    margin-left:2.5em;
}
</style>
