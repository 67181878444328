<template>
    <section class="section section--contact">
        <iframe
            width="33%"
            height="555px"
            frameborder="0"
            scrolling="no"
            marginheight="0"
            marginwidth="0"
            src="https://maps.google.com/maps?f=q&amp;source=s_q&amp;hl=en&amp;geocode=&amp;q=Mirus+Research,+618+E.+Lincoln+St,+Normal,+IL,+61761&amp;aq=t&amp;sll=37.0625,-95.677068&amp;sspn=54.269804,78.662109&amp;ie=UTF8&amp;hq=Mirus+Research,&amp;hnear=618+E+Lincoln+St,+Normal,+Illinois+61761&amp;ll=40.520633,-88.974077&amp;spn=0.006295,0.006295&amp;t=m&amp;output=embed"
        ></iframe>
        <section class="contact">
            <h1>Contact Us</h1>
            <div class="info">
                <a
                    href="https://www.google.com/maps/place/Mirus+Research/@40.5205853,-88.9762446,17z/data=!4m5!3m4!1s0x0:0x38d9e6cedee99d92!8m2!3d40.520552!4d-88.9740121?hl=en"
                    target="_blank"
                >
                    618 E. Lincoln St. Normal, IL 61761
                </a>
                <br><a href="mailto:questions@mirusresearch.com">questions@mirusresearch.com</a>
                <br><a href="tel:+1.309.828.3100">+1.309.828.3100</a>
            </div>
            <form>
                <input name="authorization" type="hidden" value="" />
                <div class="field name-box">
                    <input
                        v-model="form.name"
                        :class="{ invalid: check && invalid('name') }"
                        id="name"
                        type="text"
                        placeholder="Who Are You?" />
                    <label for="name">Name</label>
                </div>

                <div class="field email-box">
                    <input
                        v-model="form.email"
                        :class="{ invalid: check && invalid('email') }"
                        id="email"
                        type="text"
                        placeholder="name@email.com"
                    />
                    <label for="email">Email</label>
                </div>
                <div class="field hp-box">
                    <input
                        v-model="form.subject"
                        id="subject"
                        type="text"
                        placeholder="subject"
                    />
                    <label for="subject">Subject</label>
                </div>

                <div class="field msg-box">
                    <textarea
                        v-model="form.msg"
                        :class="{ invalid: check && invalid('msg') }"
                        id="msg"
                        placeholder="Your message goes here..."
                        rows="4"
                    />
                    <label for="msg">Msg</label>
                </div>

                <button class="button" @click.prevent="submit()">{{ submitText }}</button>
            </form>
        </section>
    </section>
</template>
<script>
import axios from 'axios';
export default {
    name: 'contact',
    data() {
        return {
            check: false,
            form: {},
            submitText: 'Send',
        };
    },
    methods: {
        invalid(field) {
            const val = this.form[field];
            if (!val || val.trim().length === 0 ) {
                return true;
            }
            if (field === 'email' && !/^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/.test(val)){
                return true;
            }

            return false;
        },
        submit(event) {
            this.check = true;
            let stop;
            ['name', 'email', 'msg'].forEach(f => {
                if (this.invalid(f)) {
                    stop = true;
                    return;
                }
            });
            if (stop) {
                return;
            }
            this.submitText = 'Sending . . .';

            const baseURL = process.env.NODE_ENV === 'development'?'http://localhost:3000':'';
            const ax = axios.create({baseURL: baseURL});

            ax.get(`/generate`).then(csrf => {
                let csrfToken = csrf.data.crumb;
                return ax({
                    method: 'post',
                    url: `/contact`,
                    data: {
                        email: this.form.email,
                        name: this.form.name,
                        subject: this.form.subject,
                        message: this.form.msg,
                    },
                    headers: {
                        'X-CSRF-Token': csrfToken,
                    },
                }).then(res => {
                    if (res.data.success) {
                        this.submitText = 'Sent!';
                        this.form = {};
                        this.check = false;
                    } else {
                        console.error(res);
                        this.submitText = 'Oops, something went wrong.';
                    }
                });
            })
            .catch(error => {
                console.log(error);
                this.submitText = 'Oops, something went wrong.';
            });
        },
    },
};
</script>
<style lang="scss" scoped>
.section--contact {
    display: flex;

    iframe {
        max-height: 555px;
        max-width: 33%;

        @media (max-width: 991px) {
            display: none;
        }
    }

    .contact {
        h1 {
            font-size: 45px;
        }
        width: 65%;
        padding: 2%;

        @media (max-width: 991px) {
            width: 100%;
        }

        .info {
            display: flex;
            justify-content: space-between;
            margin-bottom: 20px;

            a {
                color: #df6326;
                text-decoration: none;
                &:hover {
                    color: #97461d;
                }
            }

            @media (max-width: 767px) {
                flex-direction: column;
                a {
                    margin: 5px 0;
                }
            }
        }
    }
}

$background: #efefef;
$blue: #313a3d;
$dark-orange: #df6326;
$field-color: #e8e9ea;
$grey: #b6b6b6;
$light-grey: lighten($grey, 15%);
$orange: #f58220;

form {
    .field {
        margin-bottom: 15px;
        position: relative;
        width: 100%;

        $hover-label-width: 100px;
        $label-padding: 18px;
        $placeholder-bumper: 10px;

        label {
            min-width: 93px;
            background: $blue;
            color: $grey;
            font-size: 1em;
            left: 0;
            letter-spacing: 0.075em;
            padding: ($label-padding - 1px) 0;
            position: absolute;
            text-align: center;
            // text-transform: uppercase;
            top: 0;
            width: $hover-label-width;
        }

        input[type='text'],
        textarea {
            &::placeholder {
                color:lighten($blue,20%);
                // font-size: 0.8em;
            }
            background: $field-color;
            border: none;
            color: darken($blue,10%);
            font-size: 1em;
            letter-spacing: 0.05em;
            margin: 0;
            padding: $label-padding 0;
            padding-left: $hover-label-width + $placeholder-bumper;
            // text-transform: uppercase;
            // width: 100% - $hover-label-width - $placeholder-bumper;
            width: 100%;

            &#msg {
                resize: none;
                // height: 60px;
            }

            &:focus,
            &.focused {
                outline: none;

                + label {
                    background: $orange;
                    color: #fff;
                    width: $hover-label-width;
                }
            }

            &.focused {
                + label {
                    color: $orange;
                }
            }
        }

        &:hover {
            label {
                background: $blue;
                color: #fff;
            }
        }
        .invalid {
            + label {
                background-color: #b82a2a !important;
                color: #fff !important;
            }
        }
    }

    button {
        float: right;
        background: $orange;
        border: none;
        color: #fff;
        font-size: 1.4em;
        letter-spacing: 0.1em;
        margin-top: 10px;
        padding: 13px 50px;
        position: relative;
        // text-transform: uppercase;
        -webkit-appearance: none;

        &:hover {
            background: $blue;
            color: $orange;
        }

        &:focus {
            outline: none;
            background: $dark-orange;
        }
    }
}
</style>
