<template>
    <div>
        <Banner :title="'Our <br> Work'" />
        <Tabs :tabs="tabs" />
    </div>
 </template>
<script>
import Banner from './Banner';
import Tabs from './Tabs';
export default {
    name: 'work',
    components: {
        Banner,
        Tabs,
    },
    data() {
        return {
            image: '../assets/images/banner/mirus_header_image_2.jpg',
            tabs: [
                {
                    title: 'Your Name, Our Code',
                    heading: 'See our software, not our name',
                    html: `<p>Like any proud parent who's anxious to tell you how awesome their children are,
                    we're always excited to discuss our previous wins and accomplishments, with discretion.</p>
                    <p>
                    Most of the solutions we've designed and built are operating behind familiar corporate logos
                    and branding, while the beating technical heart bleeds Mirus orange.  We embrace that role,
                    respect privacy, and are happy to discuss concepts and capabilities
                    while honoring our NDAs and agreements.  Let's talk.</p>`,
                },
            ],
        };
    },
};
</script>
<style lang="scss" scoped>
.intro {
    background-image: url('../assets/images/banner/mirus_header_image_2.jpg');
}
</style>
