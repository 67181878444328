<template>
    <div>
       <section class="section section--services">
            <div class="shell">
                <div class="tabs">
                    <div class="tabs__head">
                        <span class="hidden-md hidden-lg select-menu dropdown">
                            <i class="ico-arrow-down"></i>
                            <select v-model="tab">
                                <option v-for="(t, i) in tabs" :key="i" :value="i">{{ t.title }}</option>
                            </select>
                        </span>

                        <nav class="tabs__nav">
                            <ul>
                                <li v-for="(t, i) in tabs" :key="i" :class="{ current: tab === i}">
                                    <a @click="tab = i" class="js-tab-link">{{ t.title }}</a>
                                </li>
                            </ul>
                        </nav>
                    </div>

                    <div class="tabs__body">
                        <div
                            :key="i"
                            v-for="(t, i) in tabs"
                            :class="{ current: tab === i}"
                            class="tab"
                        >
                            <h3>{{ t.heading }}</h3>
                            <div v-html="t.html"></div>
                       </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
 </template>
<script>
export default {
    name: 'tabs',
    props: {
        tabs: {
            type: Array,
            default: null,
        },
    },
    data() {
        return {
            tab: 0,
        };
    },
};
</script>
<style lang="scss" scoped>
.dropdown {
    position: relative;
    display: inline-block;
    vertical-align: middle;

    select {
        background-color: #df6326;
        color: #fff;
        padding: 0.5em;
        padding-right: 2.5em;
        border: 0;
        margin: 0;
        text-indent: 0.01px;
        text-overflow: '';
        -webkit-appearance: button; /* hide default arrow in chrome OSX */
    }
}
</style>
