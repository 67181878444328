<template>
    <div class="intro intro--small">
        <div class="intro__head-margin">
            <div class="intro__half-fill"></div>
         </div>
        <div class="shell">
            <div class="intro__inner">
                <div class="row">
                    <div class="col-lg-8">
                        <div class="intro__content">
                            <h1 v-html="title"></h1>
                        </div><!-- /.intro__content -->
                    </div><!-- /.col-lg-8 -->
                </div><!-- /.row -->
            </div><!-- /.intro__inner -->
        </div><!-- /.shell -->
    </div><!-- /.intro -->
</template>

<script>
export default {
    name: 'banner',
    props: {
        title: {
            type: String,
            default: 'I need a title!',
        },
    },
    data() {
        return {};
    },
};
</script>

